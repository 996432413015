import React, { useState, useEffect } from "react";
import { getFirestore, doc, setDoc, getDoc, collection, getDocs } from "firebase/firestore";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Select, MenuItem, FormControl, Autocomplete, InputLabel, Typography, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import moment from "moment";
import "moment/locale/es";
import { getCuadrillasBasic } from "../../services/cuadrillasService";
import { motivos } from "../../utils/default";

const firestore = getFirestore();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddAsistencia = () => {
    const [nroCuadrilla, setNroCuadrilla] = useState('');
    const [idCuadrilla, setIdCuadrilla] = useState('');
    const [codCuadrilla, setCodCuadrilla] = useState('');
    const [mesSeleccionado, setMesSeleccionado] = useState(moment());
    const [asistencia, setAsistencia] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [justification, setJustification] = useState("");
    const [cuadrillas, setCuadrillas] = useState([]);
    const [diasObjetivo, setDiasObjetivo] = useState('');
    const [snackbarInfo, setSnackbarInfo] = useState({ text: '', type: '', open: false });
    const [nota, setNota] = useState('');

    useEffect(() => {
        getCuadrillasBasic(setCuadrillas)

        const cargarAsistencia = async () => {
            const year = mesSeleccionado.year();
            const month = mesSeleccionado.format("MM");
            const asistenciasRef = collection(firestore, `asistencias/${year}/${month}`);

            const snapshot = await getDocs(asistenciasRef);

            setAsistencia((prevAsistencia) => {
                const nuevasAsistencias = { ...prevAsistencia }; // Mantener lo que ya estaba

                snapshot.docs.forEach((doc) => {
                    if (doc.id !== "dias") {
                        const idCuadrilla = doc.id;
                        const diasNoAsistidosFirestore = doc.data().dias_no_asistidos || {};

                        // Fusionar lo que ya había con lo nuevo
                        nuevasAsistencias[idCuadrilla] = {
                            ...nuevasAsistencias[idCuadrilla], // Mantener lo anterior
                            ...diasNoAsistidosFirestore, // Agregar lo nuevo de Firestore
                        };
                    }
                });

                return nuevasAsistencias;
            });
        };

        cargarAsistencia();
    }, [idCuadrilla, mesSeleccionado]);

    const handleDayClick = (day, cuadrillaId) => {
        setSelectedDay(day);
        setIdCuadrilla(cuadrillaId);
        setOpenDialog(true);
    };

    const saveMotivo = () => {
        setAsistencia((prev) => ({
            ...prev,
            [idCuadrilla]: {
                ...prev[idCuadrilla],
                [selectedDay]: { motivo: justification, nota: nota },
            },
        }));
        setOpenDialog(false);
    };

    const saveDiasObjetivo = async () => {
        const year = mesSeleccionado.year();
        const month = mesSeleccionado.format("MM");
        const diasRef = doc(firestore, `asistencias/${year}/${month}/dias`);

        await setDoc(diasRef, { dias_objetivo: diasObjetivo }, { merge: true });

        console.log("✅ Días objetivo guardados correctamente.");
    };

    const saveJustification = async (e) => {
        e.preventDefault();
        const year = mesSeleccionado.year();
        const month = mesSeleccionado.format("MM");
        saveDiasObjetivo();
        await Promise.all(cuadrillas.map(async (cuadrilla) => {
            const cuadrillaAsistencia = asistencia[cuadrilla.id] || {};
            await setDoc(doc(firestore, `asistencias/${year}/${month}/${cuadrilla.id}`), {
                idCuadrilla: cuadrilla.id,
                nroCuadrilla: cuadrilla.numero,
                codCuadrilla: cuadrilla.codigo,
                mes: month,
                diasObjetivo: diasObjetivo,
                dias_no_asistidos: cuadrillaAsistencia,
            }, { merge: true });
        }));

        setSnackbarInfo({ text: 'Guardado correctamente!', type: 'success', open: true });
    };


    const onChangeSelectCuadrilla = (event, cuadrilla) => {
        const nroCuadrilla = cuadrilla.numero;
        const idCuadrilla = cuadrilla.id;
        const codCuadrilla = cuadrilla?.codigo
        setNroCuadrilla(nroCuadrilla);
        setIdCuadrilla(idCuadrilla);
        setCodCuadrilla(codCuadrilla);
    }

    const onChangeSelectMotivos = (event, motivo) => {
        setJustification(motivo.resumen);
    }

    const onChangeNota = (event) => {
        setNota(event.target.value);
    }

    const handleChangeDiasObjetivo = (event) => {
        setDiasObjetivo(event.target.value);
    }

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarInfo({ text: '', type: 'info', open: false });
    };

    const action = (
        <Button color="inherit" size="small" href='/asistencia-list'>
            Ver asistencias
        </Button>
    );

    return (
        <div>
            <Typography sx={{ margin: '20px' }} variant="h4">Registro de Asistencia</Typography>
            {/* <FormControl sx={{ width: "300px" }}>
                <Autocomplete
                    options={cuadrillas}
                    id="disable-clearable"
                    disableClearable
                    onChange={(e, cuadrilla) => onChangeSelectCuadrilla(e, cuadrilla)}
                    getOptionLabel={(option) => option.numero}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    fullWidth
                    renderInput={(params) => (
                        <TextField {...params} label="Cuadrilla" placeholder="Cuadrilla" />
                    )}
                />
            </FormControl> */}
            <TextField
                type="month"
                value={mesSeleccionado.format("YYYY-MM")}
                onChange={(e) => setMesSeleccionado(moment(e.target.value))}
                style={{ marginLeft: "10px" }}
            />
            <FormControl sx={{ width: 200, margin: "0 10px" }}>
                <InputLabel id="demo-multiple-name-label">Días objetivo</InputLabel>
                <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={diasObjetivo}
                    onChange={handleChangeDiasObjetivo}
                >
                    {Array.from({ length: mesSeleccionado?.daysInMonth() }, (_, i) => (
                        <MenuItem
                            key={i + 1}
                            value={i + 1}
                        >
                            {i + 1}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                disabled={!mesSeleccionado || diasObjetivo === ''}
                variant="contained"
                onClick={saveJustification}
                style={{ marginTop: "10px" }}
            >
                Guardar Asistencia
            </Button>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Cuadrilla</strong></TableCell>
                            {Array.from({ length: mesSeleccionado.daysInMonth() }, (_, i) => (
                                <TableCell key={i} align="center">{i + 1}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cuadrillas.map((cuadrilla) => (
                            <TableRow key={cuadrilla.id}>
                                <TableCell>
                                    <Typography variant="body1">
                                        {cuadrilla.numero}
                                    </Typography>
                                </TableCell>
                                {Array.from({ length: mesSeleccionado.daysInMonth() }, (_, i) => (
                                    <TableCell
                                        key={i}
                                        align="center"
                                        style={{
                                            background: asistencia[cuadrilla.id]?.[i + 1] ? "#fdd" : "#dfd",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handleDayClick(i + 1, cuadrilla.id)}
                                    >
                                        {asistencia[cuadrilla.id]?.[i + 1]?.motivo || "✔"}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Justificar Inasistencia - Día {selectedDay}</DialogTitle>
                <DialogContent>
                    <FormControl sx={{ width: "200px" }}>
                        <Autocomplete
                            options={motivos}
                            id="disable-clearable"
                            disableClearable
                            onChange={(e, motivo) => onChangeSelectMotivos(e, motivo)}
                            getOptionLabel={(option) => `${option.resumen} - ${option.motivo}`}
                            fullWidth
                            renderInput={(params) => (
                                <TextField {...params} label="Motivo" placeholder="Motivo" />
                            )}
                        />
                    </FormControl>
                    <TextField
                        id="outlined-number"
                        label={`Nota`}
                        className="text-form__serial"
                        onChange={(e) => onChangeNota(e)}
                        name="nota"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ display: 'block', marginTop: '20px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
                    <Button onClick={saveMotivo} color="primary">Guardar</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarInfo.open}
                autoHideDuration={2000}
                onClose={handleCloseSanckbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                action={action}
            >
                <Alert onClose={handleCloseSanckbar} severity={snackbarInfo.type} sx={{ width: '100%' }} action={action}>
                    {snackbarInfo.text}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AddAsistencia;
