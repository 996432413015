import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { Table, TableBody, TableContainer, TableHead, Paper, TextField, Box } from "@mui/material";
import { StyledTableCell, StyledTableRow } from '../../utils/styled';
import moment from "moment";
import "moment/locale/es";

const firestore = getFirestore();

const Produccion = () => {
    const [mesSeleccionado, setMesSeleccionado] = useState(moment().format("YYYY-MM"));
    const [ordenes, setOrdenes] = useState([]);
    const [ordenesPorCuadrilla, setOrdenesPorCuadrilla] = useState({});

    useEffect(() => {
        const cargarOrdenes = async () => {
            const year = mesSeleccionado.split("-")[0];
            const month = mesSeleccionado.split("-")[1];
            const inicioMes = `${year}${month}01`;
            const finMes = `${year}${month}31`;

            const ordenesRef = collection(firestore, "ordenes");
            const q = query(ordenesRef, where("date", ">=", inicioMes), where("date", "<=", finMes));
            const snapshot = await getDocs(q);

            let data = snapshot.docs.map(doc => doc.data());
            setOrdenes(data);

            // Agrupar órdenes por idCuadrilla y día
            let agrupadas = {};
            data.forEach(orden => {
                const { idCuadrilla, cuadrilla, date } = orden;
                const dia = parseInt(date.slice(-2));

                if (!agrupadas[idCuadrilla]) {
                    agrupadas[idCuadrilla] = { cuadrilla, total: 0, dias: {} };
                }
                if (!agrupadas[idCuadrilla].dias[dia]) {
                    agrupadas[idCuadrilla].dias[dia] = 0;
                }

                agrupadas[idCuadrilla].dias[dia]++;
                agrupadas[idCuadrilla].total++;
            });

            // Ordenar por idCuadrilla ascendente
            const ordenadas = Object.keys(agrupadas).sort((a, b) => a - b)
                .reduce((acc, key) => {
                    acc[key] = agrupadas[key];
                    return acc;
                }, {});

            setOrdenesPorCuadrilla(ordenadas);
        };

        cargarOrdenes();
    }, [mesSeleccionado]);

    return (
        <div>
            <h2>Produccion de las Cuadrillas</h2>
            <Box display="flex" justifyContent="center" marginBottom={2}>
                <TextField
                    type="month"
                    value={mesSeleccionado}
                    onChange={(e) => setMesSeleccionado(e.target.value)}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Cuadrilla</StyledTableCell>
                            {[...Array(moment(mesSeleccionado, "YYYY-MM").daysInMonth()).keys()].map(i => (
                                <StyledTableCell key={i + 1} align="center">{i + 1}</StyledTableCell>
                            ))}
                            <StyledTableCell align="center">Total</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(ordenesPorCuadrilla).map((idCuadrilla) => (
                            <StyledTableRow key={idCuadrilla}>
                                <StyledTableCell>{ordenesPorCuadrilla[idCuadrilla].cuadrilla}</StyledTableCell>
                                {[...Array(moment(mesSeleccionado, "YYYY-MM").daysInMonth()).keys()].map(i => (
                                    <StyledTableCell key={i + 1} align="center">
                                        {ordenesPorCuadrilla[idCuadrilla].dias[i + 1] || "-"}
                                    </StyledTableCell>
                                ))}
                                <StyledTableCell align="center">{ordenesPorCuadrilla[idCuadrilla].total}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Produccion;