import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, doc, getDoc } from "firebase/firestore";
import { Table, Paper, TableHead, TableRow, TableCell, TableBody, TextField, TableContainer, Typography, Button, Box, Tooltip } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import moment from "moment";
import "moment/locale/es";

const firestore = getFirestore();

const ListAsistencias = () => {
    const [mesSeleccionado, setMesSeleccionado] = useState(moment());
    const [asistencias, setAsistencias] = useState([]);
    const [diasObjetivo, setDiasObjetivo] = useState(0);

    useEffect(() => {
        const cargarAsistencias = async () => {
            const year = mesSeleccionado.year();
            const month = mesSeleccionado.format("MM");
            const asistenciasRef = collection(firestore, `asistencias/${year}/${month}`);

            const snapshot = await getDocs(asistenciasRef);
            const data = snapshot.docs
                .filter(doc => doc.id !== "dias") // Excluir el documento "dias"
                .map(doc => ({
                    idCuadrilla: doc.id,
                    ...doc.data()
                }));

            setAsistencias(data);

            // Obtener el documento "dias" para extraer dias_objetivo
            const diasDocRef = doc(firestore, `asistencias/${year}/${month}/dias`);
            const diasDocSnap = await getDoc(diasDocRef);
            if (diasDocSnap.exists()) {
                setDiasObjetivo(diasDocSnap.data().dias_objetivo);
            } else {
                setDiasObjetivo(null); // O un valor por defecto si es necesario
            }
        };

        cargarAsistencias();
    }, [mesSeleccionado]);

    return (
        <div>
            <h2>Listado de Asistencias</h2>
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: '100%', padding: 2 }}>
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h6" color="text.secondary">
                        Días objetivo: <strong>{diasObjetivo ?? "N/A"}</strong>
                    </Typography>
                </Box>
                {/* Selector de mes centrado */}
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                    <TextField
                        type="month"
                        value={mesSeleccionado.format("YYYY-MM")}
                        onChange={(e) => setMesSeleccionado(moment(e.target.value))}
                    />
                </Box>

                {/* Botón alineado a la derecha */}
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" href="/asistencia">
                        Cargar Asistencia
                    </Button>
                </Box>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Cuadrilla</TableCell>
                            {Array.from({ length: mesSeleccionado?.daysInMonth() }, (_, i) => (
                                <TableCell key={i} align="center">{i + 1}</TableCell>
                            ))}
                            <TableCell align="center">Días Activos</TableCell>
                            <TableCell align="center">% Bruto</TableCell>
                            <TableCell align="center">% Neto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {asistencias.map((cuadrilla, index) => {
                            const totalDiasNoAsistidos = Object.keys(cuadrilla.dias_no_asistidos).length;
                            const totalDiasAsistidos = mesSeleccionado.daysInMonth() - totalDiasNoAsistidos;
                            const percentilBruto = ((totalDiasAsistidos / diasObjetivo) * 100).toFixed(2);
                            const percentilNeto = percentilBruto >= 100 ? 50 : (totalDiasAsistidos * 0.5).toFixed(2);

                            return (
                                <TableRow key={index}>
                                    <TableCell>{cuadrilla.nroCuadrilla}</TableCell>
                                    {Array.from({ length: mesSeleccionado?.daysInMonth() }, (_, i) => {
                                        const dia = cuadrilla.dias_no_asistidos[i + 1];
                                        const tieneNota = dia && dia.nota;

                                        return (
                                            <TableCell
                                                key={i}
                                                align="center"
                                                style={{
                                                    backgroundColor: dia ? "#ffdddd" : "transparent",
                                                    position: "relative",
                                                    padding: "10px",
                                                }}
                                            >
                                                {dia && (
                                                    <Tooltip title={tieneNota ? dia.nota : ""} arrow>
                                                        <div style={{ position: "relative", display: "inline-block" }}>
                                                            <span>{dia.motivo}</span>
                                                            {/* {tieneNota && (
                                                                <ChatBubbleOutlineIcon
                                                                    fontSize="small"
                                                                    sx={{
                                                                        position: "absolute",
                                                                        bottom: 2,
                                                                        right: 2,
                                                                        color: "#f4b400", // Amarillo Google
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                            )} */}
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell align="center">{totalDiasAsistidos}</TableCell>
                                    <TableCell align="center">{percentilBruto}%</TableCell>
                                    <TableCell align="center">{percentilNeto}%</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ListAsistencias;
