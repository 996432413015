export const servicesValidate = {
    ONT_TRANSCEIVER: {
        id: 20,
        maxQuantity: 1
    },
    DECO_UTP: {
        id: 22,
        maxQuantity: 6
    },
    EMBUTIDO_BOCA: {
        id: 19,
        maxQuantity: 1
    },
    INSTALACION_BOCA_RED: {
        id: 23,
        maxQuantity: 8
    }
}

export const idServicesEnabled = {
    0: [0],
    1: [1],
    2: [2],
    3: [3],
    4: [4],
    5: [5],
    6: [6],
    7: [7],
}

export const materialsValidate = {
    PRECO_50: {
        id: 0,
        maxQuantity: 1
    },
    PRECO_100: {
        id: 1,
        maxQuantity: 1
    },
    PRECO_150: {
        id: 2,
        maxQuantity: 1
    },
    PRECO_220: {
        id: 3,
        maxQuantity: 1
    },
    PRECO_300: {
        id: 4,
        maxQuantity: 1
    },
    DROP_EXTERNO: {
        id: 6,
        minQuantity: 2,
        maxQuantity: 1000
    },
    RETENEDOR: {
        id: 7,
        maxQuantity: 49
    },
    CONECTOR_SC: {
        id: 16,
        maxQuantity: 2,
        maxQuantityWithRoseta: 4
    },
    ADAPTADOR_MECANICO: {
        id: 24,
        maxQuantity: 1
    },
    ROSETA_OPTICA: {
        id: 26,
        maxQuantity: 1
    },
    CINTA_METALICA: {
        id: 8,
    },
    OJAL: {
        id: 10,
    },
    HEBILLA: {
        id: 17,
    },
    RJ45: {
        id: 13,
    },
    UTP5E: {
        id: 15,
    }
}

export const servicesId = {
    BASICA1P: 0,
    SOLOFLOW: 1,
    BASICA2P: 2
}
