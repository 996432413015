import React, { useEffect } from "react";
import { createBrowserRouter, Route, Link, RouterProvider, Navigate } from "react-router-dom";
import './App.scss';
import Button from '@mui/material/Button';

//Login
// import SignIn from "./components/Login/SignIn";
// import SignUp from "./components/Login/SignUp";
//ordenes
import AddOrdenes from './pages/Ordenes/AddOrdenes';
import AddServices from './pages/Services/AddServices';
import AddMaterials from './pages/Materials/AddMaterials';
import NavBar from './components/NavBar/NavBar';
import ListOrdenes from './pages/Ordenes/ListOrdenes';
import ListPrices from './pages/Prices/ListPrices';
import EditPrices from './pages/Prices/EditPrices';
import StockList from './pages/Stock/StockList';
import AddRemito from './pages/Stock/AddRemito';
import SignIn from './pages/Login/SignIn';
import SignUp from './pages/Login/SignUp';
import ListCuadrillas from './pages/Cuadrillas/ListCuadrillas';
import EditCuadrilla from './pages/Cuadrillas/EditCuadrilla';
import AddCuadrilla from './pages/Cuadrillas/AddCuadrillas';
import ErrorPage from './components/ErrorPage/ErrorPage';
import FacturaRemito from './pages/Stock/FacturaRemito';
import ListRemitos from './pages/Stock/RemitoList';
import SerialesSearch from './pages/Seriales/SerialesSearch';
import OTWrapper from "./pages/Ordenes/OTWrapper";
import GarantiasExcel from "./pages/Garantias/GarantiasExcel";
import AddAsistencia from "./pages/Asistencia/AddAsistencia";
import ListAsistencias from "./pages/Asistencia/ListAsistencias";
import Produccion from "./pages/Estadisticas/Produccion";
import ProdxUser from "./pages/Estadisticas/ProdxUser";

const currentUser = JSON.parse(localStorage.getItem("currentUser"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/list-ordenes?page=1" replace />
  },
  {
    path: "/ordenes",
    element: currentUser ? <OTWrapper /> : <ErrorPage />
  },
  {
    path: "/ordenes-new",
    element: currentUser ? <OTWrapper /> : <ErrorPage />
  },
  {
    path: "/list-ordenes",
    element: currentUser ? <ListOrdenes /> : <ErrorPage />
  },
  {
    path: "/services/:id",
    element: currentUser ? <AddServices /> : <ErrorPage />
  },
  {
    path: "/materials/:id",
    element: currentUser ? <AddMaterials /> : <ErrorPage />
  },
  {
    path: "/prices",
    element: currentUser && currentUser?.rol === 'admin' ? <ListPrices /> : <ErrorPage />
  },
  {
    path: "/prices/:id",
    element: currentUser && currentUser?.rol === 'admin' ? <EditPrices /> : <ErrorPage />
  },
  {
    path: "/stock",
    element: currentUser ? <StockList /> : <ErrorPage />
  },
  {
    path: "/remito",
    element: currentUser ? <AddRemito /> : <ErrorPage />
  },
  {
    path: "/remito-list",
    element: currentUser ? <ListRemitos /> : <ErrorPage />
  },
  {
    path: "/login",
    element: <SignIn />
  },
  {
    path: "/register",
    element: currentUser && currentUser?.rol === 'admin' ? <SignUp /> : <ErrorPage />
  },
  {
    path: "/cuadrillas",
    element: currentUser ? <ListCuadrillas /> : <ErrorPage />
  },
  {
    path: "/add-cuadrilla",
    element: currentUser ? <AddCuadrilla /> : <ErrorPage />
  },
  {
    path: "/cuadrilla/:id",
    element: currentUser ? <EditCuadrilla /> : <ErrorPage />
  },
  {
    path: "/imprimir/:id",
    element: currentUser ? <FacturaRemito /> : <ErrorPage />
  },
  {
    path: "/seriales",
    element: currentUser ? <SerialesSearch /> : <ErrorPage />
  },
  {
    path: "/garantias",
    element: currentUser ? <GarantiasExcel /> : <ErrorPage />
  },
  {
    path: "/asistencia",
    element: currentUser ? <AddAsistencia /> : <ErrorPage />
  },
  {
    path: "/asistencia-list",
    element: currentUser ? <ListAsistencias /> : <ErrorPage />
  },
  {
    path: "/produccion-cuadrillas",
    element: currentUser && currentUser?.rol === 'admin' ? <Produccion /> : <ErrorPage />
  },
  {
    path: "/produccion-user",
    element: currentUser && currentUser?.rol === 'admin' ? <ProdxUser /> : <ErrorPage />
  },
  {
    path: "/*",
    element: <ErrorPage />
  },
]);

function App() {
  // const { pathname } = useLocation();
  // const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  // const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  // useEffect(() => {
  //   if (pathname === '/') {
  //     window.location.href = '/list-ordenes'
  //   }
  // }, []);

  return (
    <div className="App">
      {currentUser && <NavBar />}
      <RouterProvider router={router} />
      {/* <div>
        <h2>
          SITIO EN CONSTRUCCIÓN
        </h2>
        <Button variant="outlined">Outlined</Button>
      </div>
      <div>SUCRE 3085. CÓRDOBA. ARG. CEL.: +54 9 3513 58-7901</div> */}
      {/* <Switch> */}
      {/* <Route exact path={["/ordenes"]} component={AddOrdenes} /> */}
      {/* 
            <Route
              exact
              path={!currentUser ? ["/", "/login"] : "/login"}
              component={SignIn}
            />
            <Route exact path="/register" component={SignUp} />
            <Route exact path="/profile" component={Profile} /> */}

      {/* {currentUser ? (
            <div></div>
            ) : (
              <Redirect to="/login" />
            )} */}
      {/* </Switch> */}
      {/* <FooterView /> */}
    </div>
  );
}

export default App;
