import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Menu, MenuItem } from '@mui/material';
import Link from '@mui/material/Link';
import logo from '../../cbalogo.png';

const currentUser = JSON.parse(localStorage.getItem("currentUser"));

const pages = [
    {
        name: 'Órdenes',
        submenu: [
            { name: 'Nueva OT', url: '/ordenes' },
            { name: 'Listado OT', url: '/list-ordenes' }
        ]
    },
    { name: 'Stock', url: '/stock' },
    {
        name: 'Remitos',
        submenu: [
            { name: 'Nuevo Remito', url: '/remito' },
            { name: 'Listado Remitos', url: '/remito-list' }
        ]
    },
    { name: 'Cuadrillas', url: '/cuadrillas' },
    { name: 'Buscar serial', url: '/seriales' },
    { name: 'Garantias', url: '/garantias' },
];

const pageAdmin = [
    { name: 'Precios', url: '/prices' },
    { name: 'Asistencias', url: '/asistencia-list' },
    {
        name: 'Estadísticas',
        submenu: [
            { name: 'Produccion x Cuadrillas', url: '/produccion-cuadrillas' },
            { name: 'Produccion x User', url: '/produccion-user' }
        ]
    }
];

const settings = ['Salir'];

function NavBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorElMenus, setAnchorElMenus] = React.useState({});

    const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
    const handleCloseNavMenu = () => setAnchorElNav(null);

    const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
    const handleCloseUserMenu = () => setAnchorElUser(null);

    const handleOpenMenu = (event, menuName) => {
        setAnchorElMenus((prev) => ({ ...prev, [menuName]: event.currentTarget }));
    };
    const handleCloseMenu = (menuName) => {
        setAnchorElMenus((prev) => ({ ...prev, [menuName]: null }));
    };

    const handleClickMenu = () => {
        localStorage.removeItem('currentUser');
        window.location.href = '/login';
    };

    return (
        <AppBar position="static" className='toolbar-app' sx={{ backgroundColor: '#d8d6d6' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <div>
                        <a href='/'><img decoding="async" width="50" height="30" src={logo} alt="Logo" loading="lazy" /></a>
                    </div>

                    {/* Mobile Menu */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="menu"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorElNav}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            {[...pages, ...(currentUser?.rol === 'admin' ? pageAdmin : [])].map((page) => (
                                page.submenu ? (
                                    <MenuItem key={page.name} onClick={(e) => handleOpenMenu(e, page.name)}>
                                        {page.name}
                                        <Menu
                                            anchorEl={anchorElMenus[page.name]}
                                            open={Boolean(anchorElMenus[page.name])}
                                            onClose={() => handleCloseMenu(page.name)}
                                        >
                                            {page.submenu.map((subPage) => (
                                                <MenuItem key={subPage.name} onClick={() => handleCloseMenu(page.name)}>
                                                    <Link href={subPage.url} sx={{ textDecoration: 'none', color: '#000' }}>{subPage.name}</Link>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </MenuItem>
                                ) : (
                                    <MenuItem key={page.name}>
                                        <Link href={page.url} sx={{ textDecoration: 'none', color: '#000' }}>{page.name}</Link>
                                    </MenuItem>
                                )
                            ))}
                        </Menu>
                    </Box>

                    {/* Desktop Menu */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {[...pages, ...(currentUser?.rol === 'admin' ? pageAdmin : [])].map((page) => (
                            page.submenu ? (
                                <Box key={page.name} sx={{ position: 'relative' }}>
                                    <Button
                                        onClick={(e) => handleOpenMenu(e, page.name)}
                                        sx={{ my: 2, color: 'black', marginLeft: '10px' }}
                                    >
                                        {page.name}
                                    </Button>
                                    <Menu
                                        anchorEl={anchorElMenus[page.name]}
                                        open={Boolean(anchorElMenus[page.name])}
                                        onClose={() => handleCloseMenu(page.name)}
                                    >
                                        {page.submenu.map((subPage) => (
                                            <MenuItem key={subPage.name} onClick={() => handleCloseMenu(page.name)}>
                                                <Link href={subPage.url} sx={{ textDecoration: 'none', color: '#000' }}>{subPage.name}</Link>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            ) : (
                                <Button key={page.name} href={page.url} sx={{ my: 2, color: 'black', marginLeft: '10px' }}>
                                    {page.name}
                                </Button>
                            )
                        ))}
                    </Box>

                    {/* User Menu */}
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Menú">
                            <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
                                <Typography sx={{ marginRight: '10px' }}>{currentUser?.userName}</Typography>
                                <Avatar src="/broken-image.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            anchorEl={anchorElUser}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleClickMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default NavBar;
