import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { Table, TableBody, TableContainer, TableHead, Paper, TextField, Box } from "@mui/material";
import { StyledTableCell, StyledTableRow } from '../../utils/styled';
import moment from "moment";
import "moment/locale/es";

const firestore = getFirestore();

const ProdxUser = () => {
    const [mesSeleccionado, setMesSeleccionado] = useState(moment().format("YYYY-MM"));
    const [ordenesPorUsuario, setOrdenesPorUsuario] = useState({});

    useEffect(() => {
        const cargarOrdenes = async () => {
            const [year, month] = mesSeleccionado.split("-");
            const inicioMes = `${year}${month}01`;
            const finMes = `${year}${month}31`;

            const ordenesRef = collection(firestore, "ordenes");
            const q = query(ordenesRef, where("date", ">=", inicioMes), where("date", "<=", finMes));
            const snapshot = await getDocs(q);

            let data = snapshot.docs.map(doc => doc.data());

            // Agrupar órdenes por usuario y día
            let agrupadas = {};
            data.forEach(orden => {
                const { user, date } = orden;

                // Extraer el día correctamente de "YYYYMMDD"
                const dia = parseInt(date.substring(6, 8), 10);

                if (!agrupadas[user]) {
                    agrupadas[user] = { user, total: 0, dias: {} };
                }
                if (!agrupadas[user].dias[dia]) {
                    agrupadas[user].dias[dia] = 0;
                }

                agrupadas[user].dias[dia]++;
                agrupadas[user].total++;
            });

            setOrdenesPorUsuario(agrupadas);
        };

        cargarOrdenes();
    }, [mesSeleccionado]);

    return (
        <div>
            <h2>Producción de los Usuarios</h2>
            <Box display="flex" justifyContent="center" marginBottom={2}>
                <TextField
                    type="month"
                    value={mesSeleccionado}
                    onChange={(e) => setMesSeleccionado(e.target.value)}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Usuario</StyledTableCell>
                            {[...Array(moment(mesSeleccionado, "YYYY-MM").daysInMonth()).keys()].map(i => (
                                <StyledTableCell key={i + 1} align="center">{i + 1}</StyledTableCell>
                            ))}
                            <StyledTableCell align="center">Total</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(ordenesPorUsuario).map((user) => (
                            <StyledTableRow key={user}>
                                <StyledTableCell>{ordenesPorUsuario[user].user}</StyledTableCell>
                                {[...Array(moment(mesSeleccionado, "YYYY-MM").daysInMonth()).keys()].map(i => (
                                    <StyledTableCell key={i + 1} align="center">
                                        {ordenesPorUsuario[user].dias[i + 1] || "-"}
                                    </StyledTableCell>
                                ))}
                                <StyledTableCell align="center">{ordenesPorUsuario[user].total}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ProdxUser;
